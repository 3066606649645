import jquery from "jquery";
window.$ = window.jQuery = jquery;

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

const d = new Date();

const month = d.getMonth()+1;
const day = d.getDate();

const outputDate = d.getFullYear() + 
    ((''+month).length<2 ? '0' : '') + month + 
    ((''+day).length<2 ? '0' : '') + day;

$(window).on("load", function() {

  $(".live").each(function() {
    if (parseInt($(this).attr('data-live')) < parseInt(outputDate)) {
      $(this).addClass('opacity');
    }
  });

  setTimeout(function() {
    $("#myVideo").addClass('opacity');
  }, 1800);

  setTimeout(function() {
    fun();
  }, 200);

  let counter  = 0;
  function fun() {
    const contentHeight = $("#content").outerHeight();
    $('#content').css('top', '');
    $('#content').animate({top:"-" + contentHeight + "px"}, 40000, fun);

    $("a").on("click tap", function(event) {
      event.stopPropagation();
    });

    $("button").on("click tap", function(event) {
      event.stopPropagation();
    });

    $("body").on("click tap", function() {
      $("#content").stop(true);
      $("#myVideoII").css({
        'opacity': 1,
        'z-index': 999,
      });
      $('#myVideoII').trigger('play');
      $('#content').css('top', '0');
      setTimeout(function(){
        $("#myVideoII").css({
          'opacity': 0,
          'z-index': 0,
        });
        $('#myVideoII').trigger('stop');
      }, 1100);
    });
  }
});
